























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import FileDetailLayout from '@/views/Group/TopPage/SharedFolder/FileDetailLayout.vue'
import { FileDetail, EFileFormat, IdName, FileDetailFolder } from '@/models'
import CheckFileFormat from '@/helpers/CheckFileExtension'
import GroupSharedFolderService from '@/services/GroupSharedFolderService'
import CommonSharedFolderService from '@/services/CommonSharedFolderService'
import GroupService from '@/services/GroupService'
import CheckDevice from '@/helpers/CheckDevice'

import { getCommonTopicCount } from '@/helpers/GetNotificationCount'

import { getModule } from 'vuex-module-decorators'
import CommonTopic from '@/store/modules/CommonTopic'
import store from '@/store'

const CommonTopicModule = getModule(CommonTopic, store)
/**
 * f2-602
 * f2-704
 * サークル共有フォルダ - ファイル詳細
 * 共通トピック - ファイル詳細
 */
@Component({ components: { FileDetailLayout } })
export default class FileDetailPage extends Vue {
  private isCommonTopic = !this.$route.params.groupId
  private file: FileDetail = new FileDetail()
  private eFileFormat = EFileFormat
  private modalMess: string = ''

  created() {
    this.init()
  }

  @Watch('$route.params.fileId')
  init() {
    this.getFileDetail()
  }

  /**
   * Call API get file detail
   */

  getFileDetail() {
    setTimeout(async () => {
      await (this.$refs.fileDetailLayout as any).getFileDetail()
      this.file = (this.$refs.fileDetailLayout as any).currentFile
      this.handleMarkRead()
    }, 1)
  }

  /**
   * Handle mark read file
   */
  handleMarkRead() {
    if (this.isCommonTopic) {
      CommonSharedFolderService.markCommonFileAsRead(
        Number(this.$route.params.fileId)
      )
      getCommonTopicCount()
    } else {
      GroupSharedFolderService.markFileAsRead(
        this.$route.params.groupId,
        Number(this.$route.params.fileId)
      )
    }
    //handle rebind unred count
    CommonTopicModule.SET_RELOAD_UNREAD(true)
  }

  changeFolderName(name: FileDetailFolder) {
    this.file.folder = name
  }

  /**
   * API move file to folder
   */
  handleMoveToFolder(selectedFolder: FileDetailFolder) {
    this.$blockui.show()
    GroupSharedFolderService.moveFilesToFolder({
      group_id: this.$route.params.groupId,
      file_ids: [Number(this.$route.params.fileId)],
      folder_id: selectedFolder.id
    })
      .then(res => {
        if (res.status === 200) {
          this.file.folder = selectedFolder
        }
      })
      .catch(err => {
        if (err.response.status === 404) {
          const fileLayout = this.$refs.fileDetailLayout as any
          if (err.response.data.message === 'file_not_found') {
            fileLayout.modalMess = this.$t(
              'common.message.file_not_exist'
            ) as string
            fileLayout.modalAction = () => this.jumpBack()
          } else if (err.response.data.message === 'folder_not_found') {
            fileLayout.modalMess = this.$t(
              'common.message.folder_not_exist'
            ) as string
            fileLayout.modalAction = () => {}
          }
          this.$bvModal.show('modal-error-file-detail')
        }
      })
      .finally(() => this.$blockui.hide())
  }

  /**
   * Call API to get download files stream
   */
  downloadFile() {
    //handle dowload IOS not safari
    if (
      CheckDevice.iOSBrowerName().toLowerCase() !== CheckDevice.safari &&
      CheckDevice.isMobile() &&
      CheckDevice.getMobileOS() === CheckDevice.ios
    ) {
      window.open(this.file.path)
      return
    }

    //download other
    this.$blockui.show()
    if (this.isCommonTopic) {
      CommonSharedFolderService.downloadCommonFiles([this.file.id])
        .then(res => {
          if (res.status === 200) {
            this.hanldeDownloadFile(res)
          }
        })
        .catch(err => {
          ;(this.$refs.fileDetailLayout as any).catchFileNotFoundErr(err)
        })
        .finally(() => this.$blockui.hide())
    } else {
      GroupService.downloadFiles(this.$route.params.groupId, [this.file.id])
        .then(res => {
          if (res.status === 200) {
            this.hanldeDownloadFile(res)
          }
        })
        .catch(err => {
          ;(this.$refs.fileDetailLayout as any).catchFileNotFoundErr(err)
        })
        .finally(() => this.$blockui.hide())
    }
  }

  hanldeDownloadFile(res: any) {
    const bytes = new Uint8Array(res.data)
    const blob = new Blob([bytes], { type: '*' })
    let a = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.setAttribute('target', '_blank')
    a.setAttribute('download', this.file.name)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  jumpToParentTopic() {
    if (this.file.topic) {
      let router: any = {
        name: this.isCommonTopic
          ? 'common-topic-new-feed-topic-detail'
          : 'group-new-feed-topic-detail',
        params: {
          topicId: String(this.file.topic.id)
        },
        query: {}
      }
      this.file.comment_id
        ? (router.query.commentSearchId = this.file.comment_id)
        : ''
      return router
    }
  }

  /**
   * Jump back
   */
  jumpBack() {
    if (this.isCommonTopic) {
      this.$router.push({
        name: 'common-topic-shared-folder'
      })
    } else {
      this.$router.push({
        name: 'group-shared-folder'
      })
    }
  }

  getFileType(extension: string) {
    return CheckFileFormat.getFileFormat(extension)
  }

  getIconName(extension: string) {
    return CheckFileFormat.getIconClassName(this.getFileType(extension))
  }

  formatBytes(bytes: number) {
    if (bytes == 0) return '0 KB'
    if (bytes < 1) return '1 KB'
    const k = 1024
    const sizes = ['KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }
}
